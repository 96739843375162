import { OAuth2AuthCodePkceClient } from "oauth2-pkce";

// TODO TBR
interface ClickDetail {
  sessionID: number;
  page: string;
  contextTypeID: number;
  contextID: number;
  contentTypeID: number;
  contentID: number;
  templateID: number;
  permaLink: string;
  queryString: string;
}

const ROOT_DOMAIN = process.env.ROOT_DOMAIN;

// Hydra endpoints
const PUBLIC_HYDRA_ENDPOINT = process.env.PUBLIC_HYDRA_ENDPOINT;
const AUTHORIZATIONURL = PUBLIC_HYDRA_ENDPOINT + '/oauth2/auth';
const TOKENURL = PUBLIC_HYDRA_ENDPOINT + '/oauth2/token';
// DN endpoints
const SAVE_CLICK_URL = `${ROOT_DOMAIN}/createpageview`;
const USER_DATA_URL = `${ROOT_DOMAIN}/drclick/api/user`;


const LS_SESSIONID = "DOTTNET-SESSIONID";
const HYDRA_COOKIE_PREFIX = 'oauth2';
const LS_TOKEN = 'DRCLICK-TOKEN'; // openid token
const LS_USER = 'DRCLICK-USER'; // oauth2 access token
const LS_ACCESSTOKEN = 'DRCLICK-ACCESSTOKEN';


const USER_DATA_PROPERTIES = [
  "idAnagrafica",
  "nome",
  "cognome",
  "email",
  "idCategoria",
  "categoria",
  "idComuneNascita",
  "comuneNascita",
  "idSpecializzazione",
  "specializzazione",
  "dataNascita",
  "sesso",
  "codiceFiscale",
  "dataPrivacyCliente",
  "ipPrivacyCliente",
  "flg1Active",
  "flg1Lbl",
  "flg1Value",
  "flg2Active",
  "flg2Lbl",
  "flg2Value",
  "flg3Active",
  "flg3Lbl",
  "flg3Value",
  "flg4Active",
  "flg4Lbl",
  "flg4Value"
];

interface User {
  categoria: string,      // user category
  codiceFiscale: string,  // Italian personal identification code
  cognome: string,        // Surname
  comuneNascita: string,  // Birthplace
  dataNascita: Date,      // Birthdate
  dataPrivacyCliente: Date, // date the user gave privacy consent
  email: string,          // email (could be empty)
  flg1Active: boolean,    // first consent flag used true/false
  flg1Lbl: string,        // first consent flag label 
  flg1Value: boolean,     // first consent flag value
  flg2Active: boolean,    // first consent flag used true/false
  flg2Lbl: string,        // first consent flag label 
  flg2Value: boolean,     // first consent flag value
  flg3Active: boolean,    // first consent flag used true/false
  flg3Lbl: string,        // first consent flag label 
  flg3Value: boolean,     // first consent flag value
  flg4Active: boolean,    // first consent flag used true/false
  flg4Lbl: string,        // first consent flag label 
  flg4Value: boolean,     // first consent flag value
  idAnagrafica: number,   // user id
  idCategoria: number,    // category id
  idComuneNascita: number, // Birthplace id
  idSpecializzazione: number, // specialty id
  ipPrivacycliente: string,  // user ip address when the user gave privacy consent
  nome: string,           // name
  sesso: number,          // gender 0 male 1 female
  specializzazione: string, // specialty
};




const enum Oauth2FlowStatus {
  COMPLETED = 1,
  GOTAUTHORIZATIONCODE = 2,
  NOTSTARTED = 3

}

const parametersToClear: string[] = ['code', 'scope', 'state'];



export function getDottnetSdk(
  clientId: string,
  callbackUrl: string,
  customerLoginUrl: string,
  scopes: string[],

): DottnetSdk {
  return new DottnetSdk(clientId, callbackUrl, customerLoginUrl, scopes);
}


export class DottnetSdk {

  clientPKCE: OAuth2AuthCodePkceClient;
  customerLoginUrl: string = '';
  callbackUrl: string;
  currentOauth2FlowState: Oauth2FlowStatus;
  clientId: string;
  scopes: string[];

  constructor(
    clientId: string,
    callbackUrl: string,
    customerLoginUrl: string,
    scopes: string[],
  ) {
    this.clientPKCE = this.createPKCEClient(clientId, callbackUrl, scopes);

    if (customerLoginUrl) {
      this.customerLoginUrl = customerLoginUrl;
    }

    this.clientId = clientId;
    this.scopes = scopes;
    this.callbackUrl = callbackUrl;

    console.log("1", this.clientId);
    console.log("2", this.callbackUrl);
    console.log('3', this.customerLoginUrl);

    // called here so that users don't have to remeber calling it
    this.managePartialAuth()
      .then()
      .catch((err) => console.error('constructor. managePartialAuth error: ', err));

  }

  private async managePartialAuth() {
    /* 1) understand what point of flow we are in checking which vlues are in local storage
       2) we can be at 3 points:
          a) flow has yet to start. Call clientPKCE.requestAuthorizationCode();
          b) flow has started and we have been redirected here with code_authorization. Call getTokens(clientPKCE);
          c) flow has ended. do nothing, or maybe redirect to callback (and if we are in another page?)
    */
    this.currentOauth2FlowState = Oauth2FlowStatus.NOTSTARTED;
    if (this.clientPKCE.isAuthorized() && !this.clientPKCE.isAccessTokenExpired()) {
      // we are logged, do nothing
      this.currentOauth2FlowState = Oauth2FlowStatus.COMPLETED;
      console.log('managePartialAuth. Oauth2 flow completed. do nothing');
    } if (this.clientPKCE.isReturningFromAuthServer()) {
      this.currentOauth2FlowState = Oauth2FlowStatus.GOTAUTHORIZATIONCODE;
      try {
        console.log('managePartialAuth: Oauth2 flow Code received. We need tokens');
        await this.getTokens();
        console.log('managePartialAuth. Oauth2 got tokens here is the user: ', this.decodeToken());
        if (this.customerLoginUrl.length > 0) {
          //after login, if there is a customer login that needs to do stuff backend-side, call it
          // it's calld here to be sure that we don't call it every time we access the page being logged, but only just after we actually logged
          console.log('managePartialAuth. Calling customerLogin');
          await this.customerLogin();

        }
        // clean url from oauth2 parameters
        console.log('managePartialAuth. Cleaning url parameters');
        const newUrl = removeParametersFromUrl(window.location.href, parametersToClear);
        window.history.replaceState({}, '', newUrl);
      } catch (err) {
        console.error('managePartialAuth. Oauth2 can\'t receive tokens ', err);
      }

    }

  }

  isLogged(): boolean {
    return this.clientPKCE.isAuthorized() && !this.clientPKCE.isAccessTokenExpired();
  }


  async login(
  ): Promise<DottnetSdk> {


    /* 1) understand what point of flow we are in checking which vlues are in local storage
       2) we can be at 3 points:
          a) flow has yet to start. Call clientPKCE.requestAuthorizationCode();
          b) flow has started and we have been redirected here with code_authorization. Call getTokens(clientPKCE);
          c) flow has ended. do nothing, or maybe redirect to callback (and if we are in another page?)
    */
    let doCustomerLogin = false;
    this.currentOauth2FlowState = Oauth2FlowStatus.NOTSTARTED;
    if (this.clientPKCE.isAuthorized() && !this.clientPKCE.isAccessTokenExpired()) {
      // we are logged, do nothing
      this.currentOauth2FlowState = Oauth2FlowStatus.COMPLETED;
      console.log('login. Oauth2 flow completed. do nothing');
      doCustomerLogin = true;
    } else if (this.clientPKCE.isReturningFromAuthServer()) {
      this.currentOauth2FlowState = Oauth2FlowStatus.GOTAUTHORIZATIONCODE;
    }


    switch (this.currentOauth2FlowState) {
      case Oauth2FlowStatus.GOTAUTHORIZATIONCODE:
        try {
          console.log('login. Oauth2 flow Code received. We need tokens');
          await this.getTokens();
          const user = await this.getUserData();
          console.log('login. Oauth2 got tokens here is the user: ', user);
          doCustomerLogin = true;
          // clean url from oauth2 parameters
          console.log('login. Cleaning url parameters');
          const newUrl = removeParametersFromUrl(window.location.href, parametersToClear);
          window.history.replaceState({}, '', newUrl);
        } catch (err) {
          console.error('login. Oauth2 can\'t receive tokens ', err);
        }

        break;
      case Oauth2FlowStatus.NOTSTARTED:
        console.log('login. Oauth2 flow not started. We need to start it');
        try {
          await this.clientPKCE.requestAuthorizationCode();
        } catch (err) {
          console.error('login. Oauth2 error in starting flow: ', err);
        }
        break;
      case Oauth2FlowStatus.COMPLETED:
        // flow completed do nothing
        const user = await this.getUserData();
        console.log('login. Oauth2 here is the user: ', user);
        break;
      default:
        console.error('login. Unknown oauth2 state: ', this.currentOauth2FlowState);


    }
    if (doCustomerLogin && this.customerLoginUrl.length > 0) {
      //after login, if there is a customer login that needs to do stuff backend-side, call it
      // it's calld here to be sure that we don't call it every time we access the page being logged, but only just after we actually logged
      console.log('login. Calling customerLogin');
      await this.customerLogin();

    }


    return (this);
  }

  private createPKCEClient(
    clientId: string,
    callbackUrl: string,
    scopes: string[]
  ): OAuth2AuthCodePkceClient {
    const clientPKCE = new OAuth2AuthCodePkceClient({
      scopes: scopes,
      authorizationUrl: AUTHORIZATIONURL,
      tokenUrl: TOKENURL,
      clientId: clientId,
      redirectUrl: callbackUrl,
      storeRefreshToken: false,
      // optional:
      onAccessTokenExpiry() {
        // when the access token has expired
        return this.clientPKCE.exchangeRefreshTokenForAccessToken();
      },
      onInvalidGrant() {
        // when there is an error getting a token with a grant
        console.warn(
          'createPKCEClient. Invalid grant! Auth code or refresh token need to be renewed.'
        );
        // you probably want to redirect the user to the login page here
      },
      onInvalidToken() {
        // the token is invalid, e. g. because it has been removed in the backend
        console.warn(
          'createPKCEClient. Invalid token! Refresh and access tokens need to be renewed.'
        );
        // you probably want to redirect the user to the login page here
      },
    });

    return clientPKCE;
  }



  async logout(
    postLogoutRedirectUri: string
  ) {
    try {
      //TODO: first we do login, and then logout?
      // c'era await getTokens();
      console.log('logout. Deleting cookies and localstorage anyway');

      // even if now we don't ask to hydra to save authentication
      // there could be old user authenticated. As Hydra mantains auth with cookie
      // we delete them so we are sure user is completely logged out.
      deleteCookiesStartingWith(HYDRA_COOKIE_PREFIX);
      deleteStorageStartingWith(HYDRA_COOKIE_PREFIX);
      // drclick user token
      if (localStorage.getItem(LS_USER)) {
        localStorage.removeItem(LS_USER);
      }
      if (localStorage.getItem(LS_ACCESSTOKEN)) {
        localStorage.removeItem(LS_ACCESSTOKEN);
      }
      if (localStorage.getItem(LS_TOKEN)) {
        const token = localStorage.getItem(LS_TOKEN);
        localStorage.removeItem(LS_TOKEN);
        window.location.replace(
          `${PUBLIC_HYDRA_ENDPOINT}/oauth2/sessions/logout?id_token_hint=${token}&post_logout_redirect_uri=${postLogoutRedirectUri}`
        );
      }

      this.reset();

      if (this.isLogged()) {
        console.log('logout. user logged. Proceed with logout');

      } else {
        console.log('logout. user not logged.');
      }
    } catch (err) {
      this.reset();
      console.error("logout. err ", err);
    }
  }


  private reset() {
    this.clientPKCE.reset();
  }

  private async getTokens() {

    //extracts code from url parameters
    this.clientPKCE.receiveCode();

    try {
      const tokens = await this.clientPKCE.getTokens();
      localStorage.setItem(LS_TOKEN, tokens.idToken);
      localStorage.setItem(LS_ACCESSTOKEN, tokens.accessToken)
      console.log('GetTokens. got tokens');
    } catch (err) {
      console.log('GetTokens. Oauth2 error: ', err);
    }
  }


  saveClick() {
    const params = new URLSearchParams(window.location.search);

    const consentChallenge = params.get("consent_challenge");

    const sessionId = localStorage.getItem(LS_SESSIONID);

    const clickDetail: ClickDetail = {
      sessionID: +sessionId,
      page: "consent",
      contextTypeID: 0,
      contextID: 0,
      contentTypeID: 0,
      contentID: 0,
      templateID: 0,
      permaLink: window.location.pathname,
      queryString: window.location.search,
    };

    const saveClickRequestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(clickDetail),
    };

    fetch(SAVE_CLICK_URL, saveClickRequestOptions)
      .then((response) => response.json())
      .then((data) => {
        try {
          console.log("saveClick. ecco response clickdetailid", data);
        } catch (error) {
          // TODO handle error
        }
      });
  }

  async customerLogin() {
    if (this.customerLoginUrl.length > 0) {
      const userData = await this.getUserData();
      if (userData === null) {
        console.error("customerLogin. couldn't get user data");
        return;
      }

      const customerLoginRequestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(userData),
        // same-origin sends cookies
        credentials: <RequestCredentials>"same-origin",
        redirect: <RequestRedirect>"manual",
      };

      console.log('customerLogin. RequestOption:', customerLoginRequestOptions);

      fetch(this.customerLoginUrl, customerLoginRequestOptions)
        .then(async (response: Response) => {
          console.log('received:', response);
          // 30x redirects don't work as unless you set access-control-expose-headers in your response
          // fetch returns an opaqueredirect, with all headers stripped out
          if (response.status === 200) {

            response.json()
              .then((data) => {
                // honor redirect
                console.log('customerLogin. Url (' + this.customerLoginUrl + ') response:', data);
                if (data?.location) {
                  console.log('customerLogin. redirecting to:', data.location)
                  window.location.href = data.location;
                }
              })
              .catch((err) => console.error('customerLogin. Got error converting json: ', err))
          } else if (response.status >= 300 && response.status <= 310) {
            // honor redirect
            const location = response.headers.get('location');
            console.log('customerLoginUrl (' + this.customerLoginUrl + ') location header:', location);
            if (location?.length > 0) {
              console.log('customerLogin - redirecting to:', location)
              window.location.href = location;
            }

          } else {
            // got error status from customer login
            console.error('customerLogin. Error calling Url:', this.customerLoginUrl, response.status, response.statusText)
          }
        })
        .catch((error) => console.error('customerLogin. Error calling Url:', this.customerLoginUrl, error));
    } else {
      console.log('customerLogin. Url empty');
    }

  }

  async getUserData(): Promise<User> | null {
  /*
    if (localStorage.getItem(LS_USER)) {
            let user: User;
            const jsonPayload = localStorage.getItem(LS_USER);
            if (jsonPayload !== null) {
              try {
                user = JSON.parse(jsonPayload);
                return new Promise((resolve, reject) => resolve(user));
              } catch {
                localStorage.removeItem(LS_USER);
              }
            } else {
              localStorage.removeItem(LS_USER);
            }
      
    }
  */

    if (!localStorage.getItem(LS_ACCESSTOKEN)) {
      throw (new Error('getUserData. Oauth2 no access token present'));
    }

    const accessToken = localStorage.getItem(LS_ACCESSTOKEN);

    try {
      const user = await this.callApiGet<User>(USER_DATA_URL, accessToken);
      localStorage.setItem(LS_USER, JSON.stringify(user));
      return user;
    } catch (error) {
      //console.error('Error getting user data:', USER_DATA_URL, error);
      throw (error);
    };



  }


  decodeToken(): any | null {
    if (!localStorage.getItem(LS_TOKEN)) {
      console.log('decodeToken. Oauth2 no user data present');
      return null;
    }

    const token = localStorage.getItem(LS_TOKEN);

    // decode JWT token 
    var base64Url = token.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
      // https://github.com/microsoft/TypeScript/issues/45566
      window
        .atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    const jsonData = JSON.parse(jsonPayload);


    const userData = Object.keys(jsonData).reduce(function (obj, k) {
      if (USER_DATA_PROPERTIES.includes(k)) obj[k] = jsonData[k];
      return obj;
    }, {});

    return userData;
  }



  async callApiGet<T>(url: string, accessToken: string): Promise<T> | null {

    console.log('callApiGet. Url:', url);
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };
    return fetch(url, {
      credentials: "same-origin",
      mode: 'cors',
      method: 'GET',
      headers: headers
    })
      .then(response => {
        if (!response.ok) {
          throw new Error(response.statusText)
        }
        return response.json() as Promise<{ data: T }>
      })
      .then(data => {
        console.log('CallApiGet. risultato fetch ', data);
        return <T>data;
      })
      .catch((err: Error) => {
        console.error('callApiGet. error:',url, err);
        throw (err);
      })

  }


}

function removeParametersFromUrl(url: string, parametersToBeRemoved: string[]): string {
  const urlObj: URL = new URL(url);

  // Rimuovi i parametri specificati dall'URL
  parametersToBeRemoved.forEach((parameter: string) => {
    urlObj.searchParams.delete(parameter);
  });

  // Restituisci l'URL aggiornato
  return urlObj.toString();
}

function deleteCookiesStartingWith(prefix: string) {
  var cookies: string[] = document.cookie.split(";");

  for (let i = 0; i < cookies.length; i++) {
    let cookie = cookies[i].trim();

    if (cookie.startsWith(prefix)) {
      let cookieName = cookie.split("=")[0];
      let cookieOptions = cookie.split(";").slice(1);

      // Construct the cookie deletion string with original options
      let deleteString = cookieName + "=;";

      for (let j = 0; j < cookieOptions.length; j++) {
        let option = cookieOptions[j].trim();

        if (option.toLowerCase().startsWith("path=") || option.toLowerCase().startsWith("domain=")) {
          deleteString += " " + option + ";";
        }
      }

      deleteString += " expires=Thu, 01 Jan 1970 00:00:00 UTC;";

      document.cookie = deleteString;
    }
  }
}

function deleteStorageStartingWith(prefix: string) {

  const keys = Object.keys(localStorage);
  let i = keys.length;

  while (i--) {
    if (keys[i].startsWith(prefix)) {
      localStorage.removeItem(keys[i]);
    }
  }

  return;
}

// TODO TBR

